import React from 'react';
import './QuestionRenderer.css';

import PropTypes from 'prop-types';

import { Button } from '@material-ui/core';
import { useTranslation } from 'utils/modifiedTranslation';
import IntakeAboutYou from './renderers/IntakeAboutYou';
import SingleOptionSelect from './renderers/SingleOptionSelect';
import MultiPages from './renderers/MultiPages';
import Biomarkers from './renderers/Biomarkers';
import MultipleOptionSelect from './renderers/MultipleOptionSelect';
import MonthPicker from './renderers/MonthPicker';
import SearchSelect from './renderers/SearchSelect';
import IntakeLocation from './renderers/IntakeLocation';
import DrugsReceived from './renderers/DrugsReceived';
import TextInput from './renderers/TextInput';
import NumberInput from './renderers/NumberInput';

export const QUESTION_TYPES = {
  SINGLE_OPTION_SELECT: 'SINGLE_OPTION_SELECT',
  NUMBER_INPUT: 'NUMBER_INPUT',
  BIOMARKERS: 'BIOMARKERS',
  MONTH_PICKER: 'MONTH_PICKER',
  TREATMENT_HISTORY: 'TREATMENT_HISTORY',
  MULTIPLE_OPTION_SELECT: 'MULTIPLE_OPTION_SELECT',
  OPTIONS_FROM_SERVER: 'OPTIONS_FROM_SERVER',
  LOCATION: 'LOCATION',
  TEXT_INPUT: 'TEXT_INPUT',
  TRIPLE_QUESTION_DROP_DOWN: 'TRIPLE_QUESTION_DROP_DOWN',
  PROCEDURES: 'PROCEDURES',
  MESSAGE: 'MESSAGE',
  MULTIPLE_DROP_DOWN_SELECT: 'MULTIPLE_DROP_DOWN_SELECT',
  SUB_STAGE_SELECT: 'SUB_STAGE_SELECT',
  SIMPLE_COMPOSITE: 'SIMPLE_COMPOSITE',
  COMPOSITE: 'COMPOSITE',
  INTAKE_ABOUT_YOU: 'INTAKE_ABOUT_YOU',
  INTAKE_LOCATION: 'INTAKE_LOCATION',
  MULTIPLE_OPTION_SEARCH_SELECT: 'MULTIPLE_OPTION_SEARCH_SELECT',
  DRUGS_RECEIVED: 'DRUGS_RECEIVED',
  HCP_TREATMENTS: 'HCP_TREATMENTS',
};

export const QUESTION_RENDERERS = {
  [QUESTION_TYPES.INTAKE_ABOUT_YOU]: IntakeAboutYou,
  [QUESTION_TYPES.SINGLE_OPTION_SELECT]: SingleOptionSelect,
  [QUESTION_TYPES.MULTIPLE_OPTION_SELECT]: MultipleOptionSelect,
  [QUESTION_TYPES.TREATMENT_HISTORY]: MultiPages,
  [QUESTION_TYPES.COMPOSITE]: MultiPages,
  [QUESTION_TYPES.PROCEDURES]: MultiPages,
  [QUESTION_TYPES.BIOMARKERS]: Biomarkers,
  [QUESTION_TYPES.MONTH_PICKER]: MonthPicker,
  [QUESTION_TYPES.MULTIPLE_OPTION_SEARCH_SELECT]: SearchSelect,
  [QUESTION_TYPES.INTAKE_LOCATION]: IntakeLocation,
  [QUESTION_TYPES.DRUGS_RECEIVED]: DrugsReceived,
  [QUESTION_TYPES.TEXT_INPUT]: TextInput,
  [QUESTION_TYPES.NUMBER_INPUT]: NumberInput,
};

window._QUESTION_RENDERERS = QUESTION_RENDERERS;

// missing renderers
/**
 [
    "SIMPLE_COMPOSITE",
    "TRIPLE_QUESTION_DROP_DOWN",
    "MESSAGE",
    "SUB_STAGE_SELECT",
]
 */

const QuestionRenderer = (props) => {
  const {
    q,
    onselect = () => {},
    footer,
    className,
    customRenderer,
  } = props;
  window._QUESTION_RENDERERS = QUESTION_RENDERERS;
  const Renderer = customRenderer || QUESTION_RENDERERS[q.type];
  if (!Renderer) {
    console.warn(`QuestionRenderer: "${q.type}" renderer not found`);
    return null;
  }
  return (
    <div className={`question-renderer ${className || ''}`}>
      <Renderer {...props} onselect={onselect} />
      { footer || null }
    </div>
  );
};

QuestionRenderer.propTypes = {
  q: PropTypes.any,
  footer: PropTypes.any,
  customRenderer: PropTypes.any,
  onselect: PropTypes.func,
  showTitle: PropTypes.bool,
  className: PropTypes.string,
};

export default QuestionRenderer;

export const QuestionTitle = (props) => {
  const { q } = props;
  const { t } = useTranslation();
  return (
    <>
      {q.title ? (
        <div className="question-title">
          {t(q.title)}
        </div>
      ) : null}
      {q.subtitle ? (
        <div className="question-subtitle">
          {t(q.subtitle)}
        </div>
      ) : null}
    </>
  );
};

QuestionTitle.propTypes = {
  q: PropTypes.any,
};

export const QuestionButton = (props) => {
  const { onselect, value } = props;
  const { t } = useTranslation();
  return (
    <Button
      title={t('general.save')}
      width="200px"
      action={() => {
        onselect(value);
      }}
    />
  );
};

QuestionButton.propTypes = {
  onselect: PropTypes.any,
  value: PropTypes.any,
};
