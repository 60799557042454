import React, {
  useState,
  // useEffect,
} from 'react';
import './Header.css';

import {
  useHistory,
} from 'react-router-dom';
import { useTranslation } from 'utils/modifiedTranslation';

import { ROUTE_NAME, ROUTE_PATH } from 'components/AppRoutes/constants';

// import LanguageMenu from 'new-ui/Components/LanguageMenu';
// import { ReactComponent as UserIcon } from 'new-ui/assets/icons/userIcon.svg';
// import { ReactComponent as UserIconRedDot } from 'new-ui/assets/icons/userIconRedDot.svg';
import { getProvidersAssetUrl } from 'utils/getSponsorsAssetUrl';
import {
  getLoggedUser,
} from 'new-ui/functions';
import Logo from 'new-ui/assets/leal_logo.svg';
import IconBack from 'new-ui/assets/Icon-back.svg';
import LogoNoTitle from 'new-ui/assets/logo_no_title.svg';
// import NavItemArrow from 'new-ui/assets/icons/nav_item_arrow.svg';
// import editIcon from 'new-ui/assets/icons/edit_orange.svg';
import app from 'new-ui/app';
import { ReactComponent as ProfileIcon } from 'new-ui/assets/icons/profile_icon.svg';
import NewLogo from 'new-ui/assets/images/new-logo.svg';
import UserIcon from 'new-ui/assets/icons/user_button.svg';

// import { getUnreadActionsNum } from 'new-ui/Popups/MedicalProfile/profileActions';
// import Iframe from 'new-ui/Components/Iframe';

import {
  POPUP_TYPES,
  MAIN_WEBSITE_LINKS,
  PAGES,
  URI,
  // LOCAL_STORAGE_KEYS,
  ROUTES,
} from 'new-ui/constants';
import { getIntakeUnansweredNum } from 'new-ui/Components/Intake';
import Button from 'new-ui/Components/Button';
import LanguageMenu from 'new-ui/Components/LanguageMenu';
import ProfileButton from 'new-ui/Components/ProfileButton';
import { createUserFinance } from 'new-ui/Pages/Finance';
import HeaderTabs from './HeaderTabs';
import NewButton from '../Pages/Patients/NewButton';
import useForceUpdate from 'use-force-update';

const DashboardHeader = () => {
  const { t } = useTranslation();
  const history = useHistory();
  // const hasCriticalMissing = app.hasCriticalMissingData();
  const customLogo = app.provider?.logoFileName;
  // const [numUnread, setUnread] = useState(0);
  const [custom, setCustom] = useState(null);
  const [header, setHeader] = useState(null);
  const [replaceHeader, setReplaceHeader] = useState(null);
  const [headerMiddle, setHeaderMiddle] = useState(null);
  const [headerLogo, setHeaderLogo] = useState(null);
  const isUploadPage = history.location.pathname === ROUTES.UPLOAD;
  const isTreatmentPage = history.location.pathname.includes('dashboard/treatment');
  const isSearchPage = history.location.pathname.includes('treatment');
  const isPatientsPage = history.location.pathname.includes('dashboard/patients');
  const matches = window.innerWidth <= 800;

  // const [resourceSubOpen, setResourceSubOpen] = useState(false);

  // const RESOURCES_NAV_ITEMS = [{
  //   title: t('navbar.resources.faq'),
  //   url: 'https://leal.health/faq?hideHeader=true',
  // }, {
  //   title: t('navbar.resources.questions_to_ask'),
  //   url: 'https://leal.health/questions-to-ask?hideHeader=true',
  // }, {
  //   title: t('navbar.resources.patient_support_resources'),
  //   url: 'https://leal.health/patient-support-resources?hideHeader=true',
  // }];

  app.setCustomHeader = setCustom;
  app.setHeader = setHeader;
  app.replaceHeader = setReplaceHeader;
  app.setHeaderMiddle = setHeaderMiddle;

  const logoClick = () => {
    if (app.isCurrentPage(PAGES.FINANCE) && !app.user) {
      createUserFinance({
        redirectTo: `${URI[PAGES.RESULTS]}?rematch=1`,
        subtitle: t('support_programs.create_user_subtitle_logo'),
      });
      return;
    }
    if (isUploadPage) { history.push(ROUTE_PATH[ROUTE_NAME.INTAKE]); } else if (!app.isPublic) history.push(ROUTE_PATH[ROUTE_NAME.RESULTS]);
    else if (window.location.pathname === URI[PAGES.SEARCH]) {
      window.location = MAIN_WEBSITE_LINKS.ONCOLOGISTS;
    } else if (window.location.pathname === URI[PAGES.UPLOAD]) {
      history.push('/');
    }
  };

  // const openResourcePopup = (url) => {
  //   const frame = new Iframe({
  //     url,
  //   });
  //   frame.show();
  // };

  const renderLogo = () => (
    <>
      <img
        className="logo"
        src={isUploadPage ? NewLogo : Logo}
        alt="Leal Health Logo"
        onClick={logoClick}
      />
      <img
        className="logo-no-title"
        src={LogoNoTitle}
        alt="Leal Health Logo"
        onClick={logoClick}
      />
    </>
  );

  // const toggleResourceSub = () => {
  //   setResourceSubOpen(!resourceSubOpen);
  // };

  // const closeResourceSub = (event) => {
  //   if ((!event.target?.classList?.contains('resources')
  //     && !event.target?.parentNode.classList?.contains('resources'))
  //     && (!event.target?.classList?.contains('resource-list')
  //     && !event.target?.parentNode?.classList?.contains('resource-list'))) {
  //     setResourceSubOpen(false);
  //   }
  // };

  const renderCustomLogo = () => {
    try {
      const logo = <img src={getProvidersAssetUrl(customLogo)} alt="" style={{ maxWidth: 180 }} />;
      return logo;
    } catch (e) {
      return renderLogo();
    }
  };

  // useEffect(() => {
  //   document.addEventListener('click', closeResourceSub, true);
  //   return () => {
  //     document.removeEventListener('click', closeResourceSub, true);
  //   };
  // });

  const renderIntakeHeader = ()=>{
    return app.intakeVisited ? (
      renderProfileButton()
    ) : (
      <div>
        <div
          className="profile nav-action-button enhance-precision"
          onClick={() => {
            window.location = `${URI[PAGES.INTAKE]}?unanswered=1`;
          }}
        >
          <div className="status-circle" />
          <span>{t('navbar.enhanced_result_precision')}</span>
          <ProfileIcon />
        </div>
      </div>
    );
  };

  const renderHeader = ()=>{
    return (
      <>
        {/* <div>
          <div className="resources nav-action-button" onClick={toggleResourceSub}>
            <span className="resources-button">{t('navbar.resources.title')}</span>
            <img src={NavItemArrow} alt="Arrow" className={resourceSubOpen ? 'arrow-down' : ''} />
          </div>
          {resourceSubOpen ? (
            <ul className="resource-list">
              {RESOURCES_NAV_ITEMS.map(({ title, url }) => (<li key={title} onClick={() => openResourcePopup(url)}>{title}</li>))}
            </ul>
          ) : null}
        </div> */}
        {isPatientsPage ? renderPatientHeader() : null}
        { app.user
          && !app.isCurrentPage(PAGES.PUBLIC_FINANCE)
          && !app.isCurrentPage(PAGES.FINANCE)
          && !app.isCurrentPage(PAGES.PATIENTS)
          ? renderProfileButton() : null }
      </>
    );
  };

  const renderProfileButton = ()=>{
    return (
      <div className="profile profile-button" onClick={() => { app.setPopup(POPUP_TYPES.MEDICAL_PROFILE); }}>
        <img src={UserIcon} alt="icon" />
        {/* <span>{t('navbar.enhanced_result_precision')}</span> */}
        {/* <ProfileIcon /> */}
        {/* <div className="user">
          <UserIcon width="25px" height="24px" />
          {numUnread
            ? <div className="user-unread">{numUnread}</div> : null}
        </div> */}
      </div>
    );
  };

  const renderTreatmentButtons = ()=>{
    return (
      <div className="treatment-header">
        <Button action={()=>app.setPopup(POPUP_TYPES.SHARE_WITH_DOCTOR)} className="treatment-header-share" color="#213332" title={matches ? 'Share' : 'Share with your doctor'} backgroundColor="#FFFFFF" />
        <Button action={()=>app.setPopup(POPUP_TYPES.MEDICAL_PROFILE)} className="treatment-header-update" title={matches ? 'Update profile' : 'Update your medical info'} />
      </div>
    );
  };

  const renderPatientHeader = ()=> {
    return (
      <div className="patient-header">
        <NewButton onClick={()=> { app.history.push(`${ROUTES[PAGES.SEARCH]}?new=1`); }} bgColor="#213332" />
        <ProfileButton />
      </div>
    );
  };

  return (
    <div className="header-wrapper">
      {
        replaceHeader || (
          <header className={isUploadPage ? 'header header-upload' : 'header'}>
            {(isTreatmentPage || isSearchPage) ? (
              <div className="back-button-holder">
                <Button className="header-back-btn" color="#213332CC" icon={IconBack} backgroundColor="#F2F2F7" title={matches ? 'results' : 'Back to results'} action={()=>history.push(isTreatmentPage ? ROUTES[PAGES.RESULTS] : ROUTES[PAGES.SEARCH])} />
              </div>
            ) : (
              <div className="title">

                {customLogo
                  ? renderCustomLogo()
                  : renderLogo()}
              </div>
            )}
            {
              (
                app.isCurrentPage(PAGES.RESULTS)
            || app.isCurrentPage(PAGES.FINANCE)
            || app.isCurrentPage(PAGES.PUBLIC_FINANCE)
            || app.isCurrentPage(PAGES.PATIENTS)
            || app.isCurrentPage(PAGES.SEARCH)
            || app.isCurrentPage(PAGES.FEED)
            || app.isCurrentPage(PAGES.CHAT)
              ) ? (
                <HeaderTabs />
                ) : null
            }
            {headerMiddle || null}
            {(isTreatmentPage || isSearchPage) ? renderTreatmentButtons() : (
              <div className="side">
                { header || (
                  <>
                    {custom ? (
                      <div className="custom">
                        {custom}
                      </div>
                    ) : null}
                    <div className="actions middle">
                      {/* <LanguageMenu /> */}
                      {!app.isPublic ? (
                        <>
                          {
                            (app.user && app.isSignup && getIntakeUnansweredNum() && app.isIntakeFlow(app.user?.personal.condition)) ? (
                              renderIntakeHeader()
                            ) : (
                              renderHeader()
                            )
                          }
                        </>
                      ) : null}
                    </div>
                  </>
                )}
              </div>
            )}

            {isUploadPage ? !getLoggedUser() ? (
              <div
                onClick={()=>{
                  window.location = URI[PAGES.LOGIN];
                }}
                className="header-login-block"
              >
                {t('general.login')}
              </div>
            ) : <LanguageMenu /> : null}
          </header>
        )
      }
    </div>
  );
};

export default DashboardHeader;
