import React, { useState, useEffect } from 'react';
import './ResultList.css';

import { useTranslation } from 'utils/modifiedTranslation';

import TrialItem from 'new-ui/Trial/Item';
import TreatmentItem from 'new-ui/Treatment/Item';

import Map from 'new-ui/Components/Map';
import Debug from 'new-ui/Components/Debug';

import {
  POPUP_TYPES, THEMES,
} from 'new-ui/constants';

import PropTypes from 'prop-types';

import app from 'new-ui/app';

import { getFilteredResults, randomString } from 'new-ui/functions';

import UserLocationIcon from 'new-ui/assets/icons/userLocationPin.svg';
import TrialLocationIcon from 'new-ui/assets/icons/locationPin.svg';

import Button from 'new-ui/Components/Button';
import { GA_GENERAL_CATEGORY, GA_GENERAL_EVENTS } from 'new-ui/Components/GoogleAnalytics/generalEvents';
import { openSponseredTrialInfoPopup } from 'new-ui/Components/ItemBox/functions';
import SearchTreatmentList from 'new-ui/Search/SearchTreatmentList';
import filterLocationsByTrials from '../../../functions/filterLocationsByTrials';

import ResultNote from './ResultNote';
import TreatmentList from './TreatmentList/TreatmentList';
import ResultsSkeleton from './ResultsSkeleton';
import ResultListHeader from './ResultListHeader';
import PaginatedItems from './PaginatedItems';
import TreatmentOptions from '../../TreatmentOptions';

export const RESULT_LIST_TABS = {
  TRIALS: 'TRIALS',
  TREATMENTS: 'TREATMENTS',
};

export const RESULT_LIST_TITLES = {
  TRIALS: 'general.clinical_trials',
  TREATMENTS: 'general.standard_treatments',
};

const DEFAULT_MAX_TRIALS = 5;

const ResultList = (props) => {
  const {
    all,
    treatmentsCategories = app.treatmentsCategories || [],
    // customRenderer, // todo important
    customActions,
    customNoData,
    preventFiltering = false,
    action,
    isSearchPage,
    showSkeleton = false,
    controller = {},
    mobileHeaderPrepend,
  } = props;

  let {
    trials = app.trials || [],
    treatments = app.treatments || [],
  } = props;

  if (all) {
    trials = all.trials || [];
    treatments = all.treatments || [];
  }

  window.__lastResults = {
    trials,
    treatments,
  };

  const { TRIALS, TREATMENTS } = RESULT_LIST_TABS;

  let paramsTab = null;
  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(window.location.search);
  const pTab = searchParams.get('tab');
  const paramsTabs = {
    trials: TRIALS,
    soc: TREATMENTS,
  };
  if (paramsTabs[pTab]) {
    paramsTab = paramsTabs[pTab];
    searchParams.delete('tab');
    let params = url.pathname;
    if (searchParams.toString()) {
      params += `?${searchParams.toString()}`;
    }
    window.history.pushState({}, '', params);
  }

  const isSOCSupported = app.isSOCSupported();

  const [filterOptions, setFilterOptions] = useState({});

  const handleFilterChange = (newOptions) => {
    setFilterOptions((prevOptions) => {
      const updatedOptions = { ...prevOptions, ...newOptions };
      return JSON.stringify(prevOptions) === JSON.stringify(updatedOptions) ? prevOptions : updatedOptions;
    });
  };

  const [currentTab, setTab] = useState(
    app.getParams().get('tab') || paramsTab || app.cache.tab
    || (!isSOCSupported
    || (trials.length && !treatments.length)
    || (!trials.length && !treatments.length)
    || (trials.length && treatments.length) ? TRIALS : TREATMENTS),
  );

  const [displayMap, setDisplayMap] = useState(false);
  const [keepMeUpdated, setKeepMeUpdated] = useState(false);
  const [showMaxTrials, setShowMaxTrials] = useState(DEFAULT_MAX_TRIALS);
  const { t } = useTranslation();

  app.setTab = setTab;
  app.getTab = ()=>{
    return currentTab;
  };

  const combineTrialsAndTreatments = (trials, treatments) => {
    const sponsored = [...trials, ...treatments].filter((item) => item.isSponsored);
    const filteredTrials = trials.filter((item) => !item.isSponsored);
    const filteredTreatments = treatments.filter((item) => !item.isSponsored);
    const combined = [...sponsored];

    let trialIndex = 0;
    let treatmentIndex = 0;

    while (trialIndex < filteredTrials.length || treatmentIndex < filteredTreatments.length) {
      for (let i = 0; i < 2 && trialIndex < filteredTrials.length; i++) {
        combined.push(filteredTrials[trialIndex]);
        trialIndex++;
      }

      for (let i = 0; i < 2 && treatmentIndex < filteredTreatments.length; i++) {
        combined.push(filteredTreatments[treatmentIndex]);
        treatmentIndex++;
      }
    }
    return combined;
  };

  const totalData = combineTrialsAndTreatments(trials, treatments);
  const data = currentTab === TREATMENTS ? treatments : trials;
  const results = preventFiltering ? data : getFilteredResults(data);
  let supportiveTreatments = [];
  if (currentTab === TREATMENTS) {
    try {
      supportiveTreatments = results.filter((a) => a.drug_category_of_preference_key.includes('supportive_care'));
    } catch (e) {}
  }
  const sponsored = results.filter((t) => t.isSponsored && !supportiveTreatments.includes(t));
  const nonSponsored = results.filter((t) => !t.isSponsored && !supportiveTreatments.includes(t));
  const location = app.user?.info?.country?.location;
  const locations = filterLocationsByTrials(results, app.matchesResult?.locations) || [];

  useEffect(() => {
    app.cache.tab = currentTab;
    app.sendGoogleAnalyticsEvent(GA_GENERAL_CATEGORY, currentTab === TRIALS ? GA_GENERAL_EVENTS.TRIALS_TAB_SELECTED : GA_GENERAL_EVENTS.TREATMENTS_TAB_SELECTED);
    // eslint-disable-next-line
  }, [currentTab]);

  controller.getTab = ()=>{
    return currentTab;
  };

  controller.setTab = (tab)=>{
    setTab(tab);
  };

  const markers = [];

  if (location) {
    markers.push({
      lat: location?.lat,
      lng: location?.lng,
      icon: UserLocationIcon,
      onClick: () => {
        app.setPopup(POPUP_TYPES.CHANGE_LOCATION);
      },
    });
  }

  locations.forEach((marker) => {
    markers.push({
      icon: TrialLocationIcon,
      onClick: () => {
        let trial = null;
        results.forEach((tr) => {
          if (marker.trial_ids.indexOf(tr.nct) !== -1) trial = tr;
          if (marker.trial_ids.indexOf(tr.nct_number) !== -1) trial = tr;
        });
        if (trial) {
          app.setPopup(POPUP_TYPES.GENERAL, {
            rawContent: <TrialItem popupItem item={trial} />,
          });
        }
      },
      ...marker,
    });
  });

  const renderKeepMeUpdated = () => (
    <div className="no-data-text">
      {t('match.list.no_data_keep_me_updated')}
    </div>
  );

  const noDataAction = () => {
    setKeepMeUpdated(true);
  };

  const renderNoData = () => {
    if (customNoData) return customNoData;
    const noTrials = trials.length === 0;
    const keepMeUpdatedText = () => (
      <>
        <div className="no-data">
          <div className="result-list-description">
            {keepMeUpdated ? renderKeepMeUpdated() : (
              <>
                <div className="no-data-text-1">
                  {t('match.list.no_data_text_1')}
                </div>
                <div className="no-data-text-2">
                  {t('match.list.no_data_text_2')}
                </div>
                <Button
                  title={t('match.list.no_data_button')}
                  theme={THEMES.ORANGE_FULL}
                  action={noDataAction}
                />
              </>
            )}
          </div>
          <div className="result-list-description">
            <div className="no-data-text-1">{t('match.list.no_data_update_profile')}</div>
            <Button
              title={t('match.list.no_data_update_profile_button')}
              theme={THEMES.ORANGE}
              action={() => {
                app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);
              }}
            />
          </div>
        </div>
      </>
    );

    if (currentTab === TRIALS) return keepMeUpdatedText();

    if (noTrials) return keepMeUpdatedText();

    return (
      <>
        <div className="no-data">
          <div className="show-trials result-list-description">
            <div className="no-data-text-1">
              {t('match.list.no_data_show_trials')}
            </div>
            <div className="no-data-text-2">
              {t('match.list.no_data_show_trials_bold')}
            </div>
            <Button
              title={t('match.list.no_data_show_trials_button')}
              theme={THEMES.ORANGE}
              action={() => {
                setTab(TRIALS);
              }}
            />
          </div>
        </div>
      </>
    );
  };

  const hasNoData = () => {
    return !trials.length && !treatments.length;
  };

  const ResultTitle = ({ title, action }) => (
    <div className="results-title">
      {title}
      {action && <span onClick={action}>?</span>}
    </div>
  );

  const renderItems = (items, startIdx = 0, type) => {
    const d = {};
    if (type === TREATMENTS) {
      return (
        <div className="results-items">
          {isSearchPage ? (

            <SearchTreatmentList
              treatmentsCategories={treatmentsCategories}
              itemData={
                {
                  action,
                  data: d,
                }
              }
            >
              {
                items.map((item, id) => (
                  <TreatmentItem
                    key={randomString()}
                    listItem
                    item={item}
                    number={id + startIdx}
                    action={action}
                    data={d}
                    title={item.ctgov_title}
                    isSearchPage={isSearchPage}
                  />
                ))
              }
            </SearchTreatmentList>
          ) : (
            items.map((item, id) => (
              <TreatmentItem
                key={id}
                listItem
                item={item}
                number={id + startIdx}
                action={action}
                data={d}
                title={isSearchPage ? item.ctgov_title : null}
                isSearchPage={isSearchPage}
              />
            ))
          )}
        </div>
      );
    }
    const _items = items;
    return (
      <div className="results-items">
        {
          _items.map((item, id) => (
            <TrialItem
              key={id}
              listItem
              item={item}
              number={id + startIdx}
              action={action}
              data={d}
              isSearchPage={isSearchPage}
              // title={isSearchPage ? item.ctgov_title : null}
            />
          ))
        }
      </div>
    );
  };

  const renderTreatments = () => {
    return (
      <div className="results-inner-content">
        <TreatmentList
          renderItems={(items, startIdx = 0)=>{
            return renderItems(items, startIdx, TREATMENTS);
          }}
          treatmentsCategories={treatmentsCategories}
        />
      </div>
    );
  };

  const renderTrials = () => {
    const list = [];
    if (sponsored.length) {
      if (isSOCSupported) {
        list.push(
          <ResultTitle
            key={`item_${randomString()}`}
            title={t('match.list.sponsored_trials')}
            action={openSponseredTrialInfoPopup}
          />,
        );
      }
      list.push(
        <div className="results-items" key={`item_${randomString()}`}>
          {renderItems(sponsored, 0, TRIALS)}
        </div>,
      );
    }
    if (nonSponsored.length) {
      if (isSOCSupported) {
        list.push(
          <ResultTitle key={`item_${randomString()}`} title={t('match.list.all_trials')} />,
        );
      }
      list.push(
        <div className="results-items" key={`item_${randomString()}`}>
          {renderItems(nonSponsored.slice(0, showMaxTrials !== null ? showMaxTrials : nonSponsored.length), sponsored.length, TRIALS)}
        </div>,
      );
    }
    if (supportiveTreatments.length) {
      list.push(
        <div className="results-items" key={`item_${randomString()}`}>
          {renderItems(supportiveTreatments, nonSponsored.length + sponsored.length, TRIALS)}
        </div>,
      );
    }

    return list;
  };

  const ResultWrapper = ({ children }) => (
    <>
      {
        currentTab === TRIALS ? (
          <ResultNote
            tabName={currentTab.toLowerCase()}
          />
        ) : null
      }
      {children}
    </>
  );

  const ResultMap = () => (
    <>
      <div key="map" className="map">
        <Map
          center={location}
          markers={markers}
        />
      </div>
    </>
  );

  const renderHeader = ()=>{
    return (
      <ResultListHeader
        trials={trials}
        treatments={treatments}
        displayMap={displayMap}
        setDisplayMap={setDisplayMap}
        setTab={setTab}
        customActions={customActions}
        currentTab={currentTab}
        mobileHeaderPrepend={mobileHeaderPrepend}
        isSearchPage={isSearchPage}
      />
    );
  };

  const showAllTrialsButton = ()=>{
    return showMaxTrials !== null ? (
      <div
        className="show-all-trials"
        onClick={()=>{
          setShowMaxTrials(null);
        }}
      >
        {t('general.show_more')}
      </div>
    ) : (
      <div
        className="show-all-trials"
        onClick={()=>{
          setShowMaxTrials(DEFAULT_MAX_TRIALS);
        }}
      >
        {t('general.show_less')}
      </div>
    );
  };

  // eslint-disable-next-line
  const ResultContent = () => (
    <>
      <div className="result-trials">
        <div className="results-group-title results-group-title-trials">
          <div>
            {t('general.trials')}
          </div>
        </div>
        {renderTrials()}
        <div className="result-trials-bottom">
          {showAllTrialsButton()}
        </div>
      </div>
      {
        treatments?.length ? (
          <div className="result-treatments">
            <div className="results-group-title">
              {t('general.treatments')}
            </div>
            {renderTreatments()}
          </div>
        ) : null
      }
    </>
  );

  if (action) {
    totalData.forEach((i)=>{
      i.action = action;
    });
  }

  const PaginatedContent = () => <PaginatedItems items={totalData} filter={filterOptions} />;

  const render = () => {
    // const Result = displayMap ? ResultMap : ResultContent;
    const Result = displayMap ? ResultMap : PaginatedContent;
    return (
      <ResultWrapper>
        {renderHeader()}
        <Result />
      </ResultWrapper>
    );
  };

  if (showSkeleton) {
    return (
      <ResultsSkeleton />
    );
  }

  // if (customRenderer) {
  //   return (
  //     <div className="result-list">
  //       {customRenderer(renderItems, renderHeader, currentTab)}
  //     </div>
  //   );
  // }

  return (
    <div className="widget result-list">
      {hasNoData() ? null : <TreatmentOptions onChange={handleFilterChange} />}
      {hasNoData() ? renderNoData() : render()}
      <Debug />
    </div>
  );
};

ResultList.propTypes = {
  all: PropTypes.object,
  data: PropTypes.object,
  tab: PropTypes.string,
  trials: PropTypes.array,
  treatments: PropTypes.array,
  action: PropTypes.func,
  simpleMode: PropTypes.bool, // used in search
  isSearchPage: PropTypes.bool, // used in search
  preventFiltering: PropTypes.bool, // used in search
  customRenderer: PropTypes.func, // used in search
  customNoData: PropTypes.any,
  customActions: PropTypes.func, // used in search
  stickyHeader: PropTypes.bool,
  showSkeleton: PropTypes.bool,
  treatmentsCategories: PropTypes.any,
  mobileHeaderPrepend: PropTypes.any,
  controller: PropTypes.object,
};

export default ResultList;
