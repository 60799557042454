import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Pagination from '../../../Pagination';
import './style.css';
import BaseItem from './BaseItem';
import { 
  filterByTreatmentType, 
  filterByAdministrationMethod,
  filterByClinicalTrialPhase,
  filterByInterventionalType
} from './functions';

const ITEMS_PER_PAGE = 10;

const PaginatedItems = ({items, filter}) => {
  function updatePageParam(value) {
    const url = new URL(window.location.href);
    url.searchParams.set('page', value);
    window.history.replaceState({}, '', url);
  }

  useEffect(() => {
    setCurrentPage(1)
    updatePageParam(1);
  }, [filter]);

  const location = useLocation();
  
  const query = new URLSearchParams(location.search);
  const initialPage = parseInt(query.get('page')) || 1;
  
  const [currentPage, setCurrentPage] = useState(initialPage);

  const filterItems = (items, filter) => {
    const { 
      treatment_types, 
      administration_methods,
      clinical_trial_phases,
      interventional_types
    } = filter;

    let filteredItems = filterByTreatmentType(items, treatment_types);
    filteredItems = filterByAdministrationMethod(filteredItems, administration_methods);
    filteredItems = filterByClinicalTrialPhase(filteredItems, clinical_trial_phases);
    filteredItems = filterByInterventionalType(filteredItems, interventional_types);

    return filteredItems;
  };

  const filteredItems = filterItems(items, filter);
  const selectedItems = filteredItems.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

  useEffect(() => {
    setCurrentPage(initialPage);
  }, [initialPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    document.getElementById("root").scrollIntoView({
      behavior: 'smooth'
    });
  };

  return (
    <div className='paginated-list'>
      {selectedItems.map((item, index) => (
        <BaseItem item={item} key={index} />
      ))}
      <Pagination
        totalItems={filteredItems.length} 
        perPage={ITEMS_PER_PAGE} 
        onPageChange={(page) => {
          handlePageChange(page);
      }}/>

    </div>
  );
};

export default PaginatedItems;