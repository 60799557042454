import React from 'react';
import './style.css';
import OptionCheckbox from '../../Checkbox';

const ClinicalTrials = ({ category, selectedOptions, onOptionChange, options }) => {
    const handleCheckboxChange = (value) => {
        const isChecked = selectedOptions.includes(value);
        onOptionChange(category, value, !isChecked);
    };

    return (
        <div className='clinical-trial-options'>
            <ul>
                {options.map((option) => (
                    <li key={option.value}>
                        <OptionCheckbox
                            label={option.label}
                            checked={selectedOptions.includes(option.value)}
                            onChange={() => handleCheckboxChange(option.value)}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default ClinicalTrials;
