import React, { useState, useEffect } from 'react';
import './style.css';
import OptionBox from './OptionBox';
import ClinicalTrials from './OptionTypes/ClinicalTrials';
import IntInterventionalType from './OptionTypes/IntInterventionalTypes';
import Preferences from './OptionTypes/Preferences';
import { useTranslation } from "utils/modifiedTranslation";

import { 
    treatment_types,
    clinical_trial_phases,
    interventional_types,
    administration_methods
} from './options';

const CATEGORY = {
    CLINICAL_TRIAL_PHASES: 'clinical_trial_phases',
    TREATMENT_TYPES: 'treatment_types',
    ADMINISTRATION_METHODS: 'administration_methods',
    INTERVENTIONAL_TYPES: 'interventional_types'
};

const TreatmentOptions = ({onChange}) => {
    const { t } = useTranslation();

    const [optionsArrays] = useState({
        treatment_types,
        clinical_trial_phases,
        interventional_types,
        administration_methods,
    });

    const [selectedOptions, setSelectedOptions] = useState({
        [CATEGORY.CLINICAL_TRIAL_PHASES]: [],
        [CATEGORY.TREATMENT_TYPES]: [],
        [CATEGORY.ADMINISTRATION_METHODS]: [],
        [CATEGORY.INTERVENTIONAL_TYPES]: [],
    });
    const [openedCategory, setOpenedCategory] = useState(null);
    const [isInitialLoad, setIsInitialLoad] = useState(true);

    useEffect(() => {
        if (isInitialLoad) {
            setIsInitialLoad(false);
            return;
        };
        const currentParams = new URLSearchParams(window.location.search);
        Object.values(CATEGORY).forEach(category => {
            currentParams.delete(category);
        });
        Object.entries(selectedOptions).forEach(([category, values]) => {
            values.forEach(value => currentParams.append(category, value));
        });
        const url = `${window.location.pathname}?${currentParams.toString()}`;
        window.history.replaceState({}, '', url);
        // eslint-disable-next-line
    }, [selectedOptions]);

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const initialOptions = {
            [CATEGORY.CLINICAL_TRIAL_PHASES]: [],
            [CATEGORY.TREATMENT_TYPES]: [],
            [CATEGORY.ADMINISTRATION_METHODS]: [],
            [CATEGORY.INTERVENTIONAL_TYPES]: [],
        };

        const hasInterventionalTypes = queryParams.has(CATEGORY.INTERVENTIONAL_TYPES);
        if (!hasInterventionalTypes) {
            initialOptions[CATEGORY.INTERVENTIONAL_TYPES] = [...interventional_types.map(el => el.value)];
        }
        const hasTreatmentTypes = queryParams.has(CATEGORY.TREATMENT_TYPES);
        if (!hasTreatmentTypes) {
            initialOptions[CATEGORY.TREATMENT_TYPES] = [...treatment_types.map(el => el.value)];
        }

        queryParams.forEach((value, key) => {
            if (Object.values(CATEGORY).includes(key)) {
                const selectedOptionsArray = optionsArrays[key];
                const validValues = selectedOptionsArray.filter(el => el.value === value).map(el=> el.value);
                initialOptions[key].push(...validValues)
            }
        });
        setSelectedOptions(initialOptions);
        setIsInitialLoad(false);
        if (onChange) {
            onChange(initialOptions);
        }

        // Check for the 'open' query parameter
        const openCategory = queryParams.get('open');
        if (openCategory && Object.values(CATEGORY).includes(openCategory)) {
            setOpenedCategory(openCategory);
        }
        // eslint-disable-next-line
    }, []);

    const handleOptionChange = (category, value, isChecked) => {
        setSelectedOptions(prev => {
            const updatedCategory = isChecked
                ? [...prev[category], value]
                : prev[category].filter(item => item !== value);
    
            const newOptions = {
                ...prev,
                [category]: updatedCategory,
            };
    
            if (onChange) {
                onChange(newOptions);
            }
    
            return newOptions;
        });
    };    

    const handleOpen = (category) => {
        setOpenedCategory(category);
    };

    const shouldOpenCategory = (category) => {
        return category === openedCategory;
    };    

    const wrapperClassName = `treatment-options-wrapper ${openedCategory ? openedCategory : ''}`;
    return (
        <div className={wrapperClassName}>
            {/* <div className='treatment-options-header'>
                <h3 className='options-title'>{t("treatment_options.title")}</h3>
            </div> */}
            <div className='treatment-options-mobile'>
                <OptionBox
                    onOpen={() => handleOpen(CATEGORY.INTERVENTIONAL_TYPES)}
                    title={t("treatment_options.option.title.interventional_type")}
                    selectedCount={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES].length}
                    initiallyOpen={shouldOpenCategory(CATEGORY.INTERVENTIONAL_TYPES)}
                    contentComponent={
                        <IntInterventionalType
                            category={CATEGORY.INTERVENTIONAL_TYPES}
                            selectedOptions={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES]}
                            onOptionChange={handleOptionChange}
                            options={interventional_types}
                        />
                    }
                />
                <OptionBox
                    onOpen={() => handleOpen(CATEGORY.CLINICAL_TRIAL_PHASES)}
                    title={t("treatment_options.option.title.preferences")}
                    withTitle={false}
                    selectedCount={[ 
                        selectedOptions[CATEGORY.TREATMENT_TYPES].length +
                        selectedOptions[CATEGORY.ADMINISTRATION_METHODS].length +
                        selectedOptions[CATEGORY.CLINICAL_TRIAL_PHASES].length
                    ]}
                    contentComponent={
                        <Preferences
                            category_1={CATEGORY.TREATMENT_TYPES}
                            category_2={CATEGORY.ADMINISTRATION_METHODS}
                            category_3={CATEGORY.CLINICAL_TRIAL_PHASES}
                            options_1={treatment_types}
                            options_2={administration_methods}
                            options_3={clinical_trial_phases}
                            selectedOptions_1={selectedOptions[CATEGORY.TREATMENT_TYPES]}
                            selectedOptions_2={selectedOptions[CATEGORY.ADMINISTRATION_METHODS]}
                            selectedOptions_3={selectedOptions[CATEGORY.CLINICAL_TRIAL_PHASES]}
                            onOptionChange={handleOptionChange}
                            options={clinical_trial_phases}
                            onCancel={() => handleOpen(CATEGORY.CLINICAL_TRIAL_PHASES)}
                        />
                    }
                />
            </div>
            <div className='treatment-options-desktop'>
                <OptionBox
                    onOpen={() => handleOpen(CATEGORY.TREATMENT_TYPES)}
                    title={t("treatment_options.option.title.treatment_type")}
                    selectedCount={selectedOptions[CATEGORY.TREATMENT_TYPES].length}
                    contentComponent={
                        <ClinicalTrials
                            category={CATEGORY.TREATMENT_TYPES}
                            selectedOptions={selectedOptions[CATEGORY.TREATMENT_TYPES]}
                            onOptionChange={handleOptionChange}
                            options={treatment_types}
                        />
                    }
                />
                <OptionBox
                    onOpen={() => handleOpen(CATEGORY.INTERVENTIONAL_TYPES)}
                    title={t("treatment_options.option.title.interventional_type")}
                    selectedCount={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES].length}
                    initiallyOpen={shouldOpenCategory(CATEGORY.INTERVENTIONAL_TYPES)}
                    contentComponent={
                        <IntInterventionalType
                            category={CATEGORY.INTERVENTIONAL_TYPES}
                            selectedOptions={selectedOptions[CATEGORY.INTERVENTIONAL_TYPES]}
                            onOptionChange={handleOptionChange}
                            options={interventional_types}
                        />
                    }
                />
                <OptionBox
                    onOpen={() => handleOpen(CATEGORY.ADMINISTRATION_METHODS)}
                    title={t("treatment_options.option.title.administration_method")}
                    selectedCount={selectedOptions[CATEGORY.ADMINISTRATION_METHODS].length}
                    contentComponent={
                        <ClinicalTrials
                            category={CATEGORY.ADMINISTRATION_METHODS}
                            selectedOptions={selectedOptions[CATEGORY.ADMINISTRATION_METHODS]}
                            onOptionChange={handleOptionChange}
                            options={administration_methods}
                        />
                    }
                />
                <OptionBox
                    onOpen={() => handleOpen(CATEGORY.CLINICAL_TRIAL_PHASES)}
                    title={t("treatment_options.option.title.clinical_trial_phase")}
                    selectedCount={selectedOptions[CATEGORY.CLINICAL_TRIAL_PHASES].length}
                    contentComponent={
                        <ClinicalTrials
                            category={CATEGORY.CLINICAL_TRIAL_PHASES}
                            selectedOptions={selectedOptions[CATEGORY.CLINICAL_TRIAL_PHASES]}
                            onOptionChange={handleOptionChange}
                            options={clinical_trial_phases}
                        />
                    }
                />
            </div>
        </div>
    );
};

export default TreatmentOptions;