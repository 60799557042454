import React, {
  useEffect,
  useState,
} from 'react';
import './Intake.css';
import app from 'new-ui/app';
// import { useTranslation } from 'utils/modifiedTranslation';

import Intake from 'new-ui/Components/Intake';
import {
  MATCH_FLOWS, PAGES, POPUP_TYPES, ROUTES,
} from 'new-ui/constants';
import ButtonIcon from 'new-ui/assets/icons/intake_icon.svg';
import BackIcon from 'new-ui/assets/icons/profile_icon.svg';

import Lottie from 'lottie-react';
import LottieAnimation from 'new-ui/assets/lottie/check.json';
import LottieAnimationLoading from 'new-ui/assets/lottie/intake_loading.json';
import LottieAnimationNewResults from 'new-ui/assets/lottie/new_results.json';

import { render, unmountComponentAtNode } from 'react-dom';
import { GA_INTAKE_CATEGORY, GA_INTAKE_EVENTS } from 'new-ui/Components/GoogleAnalytics/generalEvents';
import { getLottie, getOverlay, waitFor } from 'new-ui/functions';
import { authenticationActions } from 'components/Authentication/actions';
import { useTranslation } from 'utils/modifiedTranslation';
import configs from 'configs';

window.__LottieAnimation = LottieAnimation;

let initResults = null;
let isLoading = false;
let oldUser = null;
// const gotoResultsFlag = false;

let $loading;

let updateNum = 0;

const IntakePage = () => {
  // eslint-disable-next-line no-unused-vars
  const [locationError, setLocationError] = useState(null);
  const [user, setUser] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    app.intakeVisited = true;
    initResults = null;
    $loading = null;

    app.sendGoogleAnalyticsEvent(GA_INTAKE_CATEGORY, GA_INTAKE_EVENTS.INTAKE_PAGE_VIEW);

    const fetch = async () => {
      app.setShowPreloader(true);
      app.getTrials(MATCH_FLOWS.BASIC, ()=>{
        console.log('return from getTrials');
        renderResults();
        initResults = [...app.trials];
        app.setShowPreloader(false);
        if (app.user) return setUser(app.user);
        // debugger;
      });
      // app.setShowPreloader(true);
      // await app.update({
      //   rematch: true,
      // });
      // console.log('fetch', app.user, app.trials);
      // initResults = [...app.trials];
      // renderResults();
      // setUser(app.user);
      // app.setShowPreloader(false);
      // if (app.user) {
      //   if (!app.trials || !app.trials.length) {
      //     app.getTrials(MATCH_FLOWS.BASIC);
      //     waitFor(()=>{
      //       return app.fetchedTrials;
      //     }, ()=>{
      //       if (gotoResultsFlag) {
      //         gotoResults();
      //         return;
      //       }
      //       console.log('here! 3', app.trials);
      //       initResults = [...app.trials];
      //       renderResults();
      //     }, ()=>{
      //       if (gotoResultsFlag) {
      //         gotoResults();
      //         return;
      //       }
      //       console.log('app trials not loaded timeout');
      //     }, 20000);
      //   }
      //   oldUser = JSON.parse(JSON.stringify(app.user));
      //   setTimeout(renderResults, 10);
      //   return setUser(app.user);
      // }
      // app.user = await app.getUser();
      // await app.getTrials(MATCH_FLOWS.BASIC, ()=>{
      //   initResults = [...app.trials];
      //   oldUser = JSON.parse(JSON.stringify(app.user));
      //   console.log('here!', initResults);
      //   setUser(app.user);
      // });
      // setTimeout(renderResults, 10);
    };

    app.setHeader(
      <div>
        <div
          className="nav-action-button personalized-results-button"
          onClick={gotoResultsAction}
        >
          <div className="personalized-results-amount opacity-zero" />
          <div className="personalized-results-loader" />
          <span className="personalized-results-title">Personalized Results</span>
          <div className="flex-middle personalized-results-icon">
            <img src={ButtonIcon} alt="" />
          </div>
        </div>
      </div>,
    );

    fetch();

    app.intercom.hidden = true;

    return ()=>{
      app.setHeader(null);
      app.intercom.hidden = false;
    };
    // eslint-disable-next-line
  }, []);

  const requestLocation = async () => {
    let updatedUser = {};
    if (navigator.geolocation) {
      try {
        navigator.geolocation.getCurrentPosition(
          async (position) => {
            const reverseGeocodeLocation = await reverseGeocode(position.coords.latitude, position.coords.longitude);
            if (position) {
              const locationToUpdate = {
                location:
                {
                  approximateLat: position.coords.latitude,
                  approximateLng: position.coords.longitude,
                  lat: position.coords.latitude,
                  lng: position.coords.longitude,
                },
                name: null,
                label: reverseGeocodeLocation.address,
                state: reverseGeocodeLocation.country,
              };
              updatedUser = {
                ...app.user,
                info: {
                  ...app.user.info,
                  country: locationToUpdate,
                },
              };

              await app.setUser(updatedUser);

              app.user.info.country = locationToUpdate;
              await app.store.dispatch(authenticationActions.updateUser(updatedUser, true, app.user));
            }
          },
          (err) => {
            console.error('Error getting location:', err);
            setLocationError(err.message);
          },
        );
      } catch (error) {
      }
    } else {
      setLocationError('Geolocation is not supported by this browser.');
    }
  };

  const reverseGeocode = async (latitude, longitude) => {
    const apiKey = configs.geosearchapi;
    const apiUrl = `https://api.opencagedata.com/geocode/v1/json?q=${latitude}+${longitude}&key=${apiKey}`;

    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.status.code === 200) {
        // Successful response from OpenCage
        const result = data.results[0];
        return {
          address: result.formatted, // Get the formatted address
          country: result.components.country, // Extract country
          state: result.components.state || null, // Extract state (if available)
          // ... other components you need (city, postcode, etc.)
        };
      }

      // eslint-disable-next-line no-console
      console.log(`OpenCage Geocoding request failed with code: ${data.status.code}`);
      return {
        address: null,
        country: null,
        state: null,
      };
    } catch (error) {
      console.error('Error fetching geocoding data:', error);
      return {
        address: null,
        country: null,
        state: null,
      };
    }
  };

  const gotoResultsAction = ()=>{
    if (isLoading) {
      $loading = getOverlay();
      const $lottie = getLottie(LottieAnimationLoading);
      $loading.append($lottie);
      document.body.appendChild($loading);
      $loading.show();
    } else gotoResults();
  };

  const gotoResults = ()=>{
    // window.location = `${ROUTES[PAGES.RESULTS]}?rematch=1&results=1`;
    // return;
    if (isLoading) {
      waitFor(()=>{
        return isLoading === false;
      }, ()=>{
        window.location = `${ROUTES[PAGES.RESULTS]}?rematch=1`;
      }, ()=>{
        window.location = `${ROUTES[PAGES.RESULTS]}?rematch=1`;
      });
      return;
    }
    app.history.push(`${ROUTES[PAGES.RESULTS]}`);
    const doGoto = ()=>{
      // console.log('here', initResults.map((a)=>a._id), app.trials.map((a)=>a._id));
      console.log('initResults', initResults);
      try {
        if (JSON.stringify(initResults.map((a)=>a._id)) === JSON.stringify(app.trials.map((a)=>a._id))) return;
      } catch (e) {
        console.log('here');
      }
      initResults = null;
      waitFor(()=>{
        const $list = document.querySelector('.widget.result-list');
        if ($list) return true;
      }, ()=>{
      // console.log('show new results');
        const $list = document.querySelector('.widget.result-list');
        const $newResults = getOverlay();
        $newResults.classList.add('new-results');
        const $lottie = getLottie(LottieAnimationNewResults);
        $newResults.append($lottie);
        $list.appendChild($newResults);
        $newResults.show();
        setTimeout(()=>{
          $newResults.remove();
        }, 3000);
      });
    };
    const results = app.numResults(true);
    if (!results || !initResults) {
      // console.log('no results', app.fetchedTrials, results, initResults);
      waitFor(()=>{
        // console.log('here 5', initResults, app.numResults(true));
        return initResults;
      }, ()=>{
        doGoto();
      }, ()=>{
        // console.log('timeout');
        window.location = `${ROUTES[PAGES.RESULTS]}`;
      });
      return;
    }
    doGoto();
  };
  const toggleLoader = (show)=>{
    const $amount = document.querySelector('.personalized-results-amount');
    const $loader = document.querySelector('.personalized-results-loader');
    if (!$amount || !$loader) return;
    $amount.classList[show ? 'add' : 'remove']('opacity-zero');
    $loader.classList[show ? 'remove' : 'add']('hidden');
  };

  const showLoader = ()=>{
    const $loader = document.querySelector('.personalized-results-loader');
    if (!$loader) return;
    unmountComponentAtNode(document.querySelector('.personalized-results-loader'));
    render(
      <Lottie animationData={LottieAnimation} loop />,
      document.querySelector('.personalized-results-loader'),
    );
    setTimeout(()=>{
      toggleLoader(true);
      setTimeout(()=>{
        toggleLoader(false);
      }, 2000);
    }, 10);
  };

  window.__showLoader = showLoader;

  const renderResults = ()=>{
    const num = (app.trials?.length || 0);
    const $r = document.querySelector('.personalized-results-amount');
    if ($r) $r.innerHTML = num;
    showLoader();
    if ($loading) {
      $loading.remove();
      $loading = null;
      gotoResults();
    }
  };

  const update = async (user)=>{
    const currentUpdateNum = updateNum + 1;
    updateNum++;
    isLoading = true;
    const newUser = await app.store.dispatch(authenticationActions.updateUser(user, true, oldUser));
    app.user = newUser;
    oldUser = JSON.parse(JSON.stringify(app.user));
    await app.getTrials(MATCH_FLOWS.BASIC);
    if (currentUpdateNum !== updateNum) return; // execute only if last update
    isLoading = false;
    renderResults();
  };

  const params = new URLSearchParams(window.location.search);

  const openProfile = async ()=>{
    if (!app.user.info.country?.lat || !app.user.info.country?.lng) {
      await requestLocation();
      app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);
    }
    app.setPopup(POPUP_TYPES.MEDICAL_PROFILE);
  };

  let qid = params.get('qid');

  let customQuestions;
  const intakeQuestion = params.get('intake_question');
  if (intakeQuestion) {
    customQuestions = [intakeQuestion];
    qid = intakeQuestion;
  }

  return (
    <div className="intake-page">
      { user ? (
        <>
          <div className="intake-wrapper">
            <div className="intake-page-title">
              {t('intake.page_title')}
            </div>
            <Intake
              user={user}
              onUpdate={update}
              gotoResultsAction={gotoResultsAction}
              part={params.get('section')}
              qid={qid}
              customQuestions={customQuestions}
              showOnlyUnanswered={params.get('unanswered')}
            />
            <div className="back-to-results">
              <div
                className="back-to-results-button"
                onClick={openProfile}
              >
                <div className="back-to-results-button-icon">
                  <img src={BackIcon} alt="icon" />
                </div>
                <div className="back-to-results-button-text">
                  {t('intake.goto_profile')}
                </div>
              </div>
              <div
                className="back-to-results-button back-to-results-button-results"
                onClick={gotoResults}
              >
                <div className="back-to-results-button-icon">
                  <img src={ButtonIcon} alt="" />
                </div>
                <div className="back-to-results-button-text">
                  {t('intake.goto_results')}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null }
    </div>
  );
};

export default IntakePage;
