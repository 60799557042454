/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import app from 'new-ui/app';
import './AddCaregiver.css';
import Form, { FORM_INPUT_TYPES } from 'new-ui/Components/Form';
import Button from 'new-ui/Components/Button';
import FooterGFX from 'new-ui/assets/hipaa.svg';
import ArrowCircle from 'new-ui/assets/icons/arrow-circle-right.svg';
import { t, useTranslation } from 'utils/modifiedTranslation';
import { getInitialConditionProfileByIndication, signup } from 'new-ui/functions';
import useRecaptcha from 'utils/hooks/useRecaptcha';
import { INDICATIONS } from 'new-ui/constants';
import { USER_TYPES, USER_TYPES_VALUES } from 'new-ui/Components/QuestionRenderer/renderers/IntakeAboutYou';

const GA = {
  category: 'add_caregiver_popup',
  events: {
    error: 'add_caregiver popup signup error',
  },
};

const DEFAULT_INDICATION = INDICATIONS.AML;

const AddCaregiver = (props)=>{
  const {
    onSignup,
    indication = DEFAULT_INDICATION,
    hideOrganization = false,
  } = props;
  const [form, setForm] = useState();
  const { recaptcha, getRecaptchaTokenAsync } = useRecaptcha();
  const [condition_profile, setConditionProfile] = useState(null);
  const [error, setError] = useState();
  const { t } = useTranslation();

  const {
    title = t('add_caregiver.title'),
    subtitle = t('add_caregiver.subtitle'),
  } = props;

  useEffect(()=>{
    const fetch = async ()=>{
      // const indications = await app.getIndications();
      const _condition_profile = await getInitialConditionProfileByIndication(DEFAULT_INDICATION);
      setConditionProfile(_condition_profile);
      const _form = {
        data: {
          organization_name: '',
          role: '',
          email: '',
          phone: '',
        },
        inputs: {
          organization_name: {
            type: FORM_INPUT_TYPES.INPUT,
            label: 'Organization',
          },
          role: {
            label: 'Role',
            type: FORM_INPUT_TYPES.RADIO,
            options: [
              {
                title: t('create_profile.role.medical_team'),
                value: USER_TYPES_VALUES[USER_TYPES.MEDICAL_TEAM]
              },
              {
                title: t('create_profile.role.patient_navigator'),
                value: USER_TYPES_VALUES[USER_TYPES.PATIENT_NAVIGATOR]
              },
              {
                title: t('create_profile.role.patient'),
                value: USER_TYPES_VALUES[USER_TYPES.PATIENT]
              },
              {
                title: t('create_profile.role.caregiver'),
                value: USER_TYPES_VALUES[USER_TYPES.CAREGIVER]
              },
              {
                title: t('create_profile.role.other'),
                value: USER_TYPES_VALUES[USER_TYPES.OTHER]
              },
            ],
          },
          email: {
            type: FORM_INPUT_TYPES.INPUT,
            required: true,
            icon: 'email',
            title: 'Email',
            placeholder: '',
            isRequired: true,
          },
          phone: {
            type: FORM_INPUT_TYPES.PHONE,
            icon: 'phone',
            title: 'Phone',
            placeholder: '651465',
            className: 'registration-phone',
          },
          // indication: {
          //   type: FORM_INPUT_TYPES.SELECT,
          //   options: indications,
          // },
        },
      };

      if (hideOrganization) delete _form.inputs.organization_name;

      setForm(_form);
    };
    fetch();
    // eslint-disable-next-line
  }, []);

  const onChange = (_form)=>{
    console.log(_form);
  };

  const register = async ()=>{
    if (!form.data.email || !form.data.role) return;
    app.setShowPreloader(true);
    signup({
      email: form.data.email,
      indication,
      recaptchaToken: await getRecaptchaTokenAsync(),
      condition_profile: await getInitialConditionProfileByIndication(DEFAULT_INDICATION),
      info: {
        // user_type: 'doctor',
        user_type: form.data.role
      },
      personal: {
        providerId: window._provider || '',
        providerCode: window._provider || '',
      },
      onsignup: (user) => {
        console.log('user signup success :: addCaregiver', user);
        app.intercom.updateUserAttributes({
          email: form.data.email,
          condition: indication,
        }, user.info.user_wix_id);
        // app.sendGoogleAnalyticsEvent(GA.category, GA.events.success, { name: 'user_signup_success' });
        app.setShowPreloader(false);
        onSignup(user);
      },
      onerror: (e) => {
        app.setShowPreloader(false);
        setError('Signup Error');
        app.sendGoogleAnalyticsEvent(GA.category, GA.events.error, { name: 'user_signup_error' });
      },
    });
  };

  return (
    <div className="add-caregiver">
      {recaptcha}
      {
        form ? (
          <>
            <div className="add-caregiver-title">{title}</div>
            <div className="add-caregiver-subtitle">{subtitle}</div>
            <div className="add-caregiver-form">
              <Form form={form} onChange={onChange} />
            </div>
            {
              error ? (
                <div className="add-caregiver-error-handling">
                  {
                    error
                  }
                </div>
              ) : null
            }
            <div className="add-caregiver-buttons">
              <Button
                title={window.t('general.save')}
                className="add-caregiver-button"
                forwardIcon={ArrowCircle}
                action={register}
              />
            </div>
            <div className="add-caregiver-footer">
              <div className="footer-gfx">
                <img src={FooterGFX} alt="text" />
              </div>
              <div className="add-caregiver-agreement">
                {'By clicking "Save" I agree to the '}
                <a className="signup-page-link-color" href="https://www.leal.health/terms">Terms</a>
                {' and '}
                <a className="signup-page-link-color" href="https://www.leal.health/privacy-policy">Privacy Policy</a>
                {' . '}
                <br />
                {' This site is protected by reCAPTCHA and the '}
                <a className="signup-page-link-color" href="https://policies.google.com/privacy"> Google Privacy Policy </a>
                {' and '}
                <a className="signup-page-link-color" href="https://policies.google.com/terms"> Term of Service </a>
                {' apply. '}
              </div>
            </div>
          </>
        ) : null
      }
    </div>
  );
};

export default AddCaregiver;
